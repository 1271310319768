.sr-only {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

[hidden] {
    display: none !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

hr,
.hr {
    height: 1px;
    margin: calc(3 * var(--gap)) 0;
    opacity: 0.1;
    background-color: currentcolor;
    color: inherit;
    border: 0;
}
