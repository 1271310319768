:root {
    --font-size-000: 0.75rem;
    --font-size-00: 0.875rem;
    --font-size-0: 1rem;
    --font-size-1: 1.125rem;
    --font-size-2: 1.25rem;
    --font-size-3: 1.5rem;
    --font-size-4: 1.875rem;
    --font-size-5: 2.25rem;
    --font-size-6: 3rem;
    --font-size-7: 3.75rem;
    --font-size-8: 4.5rem;
    --font-size-9: 6rem;
    --font-size-10: 8rem;
    --line-height: 1;
    --line-height-xs: 1.125;
    --line-height-sm: 1.275;
    --line-height-md: 1.5;
    --line-height-lg: 1.625;
    --line-height-xl: 2;
    --line-height-xxl: 4;
}
