:root {
    --global-font: var(--font-sans-serif);
    --global-background: var(--color-white);
    --global-text: var(--color-black);
    --global-border-radius: 2px;
    --global_warning-color: var(--color-orange-darken-2);
    --global-container-width: 75vw;
    --global-container-max-width: 1920px;
    --global-header-height: 100px;
}
