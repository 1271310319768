.container,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: var(--gap, 1rem);
    padding-left: var(--gap, 1rem);
}

@media (min-width: 600px) {
    .container-sm,
    .container {
        max-width: 540px;
    }
}

@media (min-width: 900px) {
    .container-md,
    .container-sm,
    .container {
        max-width: 860px;
    }
}

@media (min-width: 1200px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1500px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1320px;
    }
}

@media (min-width: 1800px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1720px;
    }
}
.row {
    --gap-x: var(--gap, 1rem);
    --gap-y: 0;
    margin-top: calc(-1 * var(--gap-y));
    margin-right: calc(-0.5 * var(--gap-x));
    margin-left: calc(-0.5 * var(--gap-x));
    display: flex;
    flex-wrap: wrap;
    transition: opacity var(--transition-out) var(--transition-timing);
}
.row > * {
    width: 100%;
    max-width: 100%;
    margin-top: var(--gap-y);
    padding-right: calc(0.5 * var(--gap-x));
    padding-left: calc(0.5 * var(--gap-x));
    flex-shrink: 0;
}

.col {
    flex: 1 0 0%;
}

.col-auto {
    width: auto;
    flex: 0 0 auto;
}

.col-1 {
    width: calc(100% / 12);
    flex: 0 0 auto;
}

.col-2 {
    width: calc(100% / 12 * 2);
    flex: 0 0 auto;
}

.col-3 {
    width: calc(100% / 12 * 3);
    flex: 0 0 auto;
}

.col-4 {
    width: calc(100% / 12 * 4);
    flex: 0 0 auto;
}

.col-5 {
    width: calc(100% / 12 * 5);
    flex: 0 0 auto;
}

.col-6 {
    width: calc(100% / 12 * 6);
    flex: 0 0 auto;
}

.col-7 {
    width: calc(100% / 12 * 7);
    flex: 0 0 auto;
}

.col-8 {
    width: calc(100% / 12 * 8);
    flex: 0 0 auto;
}

.col-9 {
    width: calc(100% / 12 * 9);
    flex: 0 0 auto;
}

.col-10 {
    width: calc(100% / 12 * 10);
    flex: 0 0 auto;
}

.col-11 {
    width: calc(100% / 12 * 11);
    flex: 0 0 auto;
}

.col-12 {
    width: 100%;
    flex: 0 0 auto;
}

.offset-1 {
    margin-left: calc(100% / 12);
}

.offset-2 {
    margin-left: calc(100% / 12 * 2);
}

.offset-3 {
    margin-left: calc(100% / 12 * 3);
}

.offset-4 {
    margin-left: calc(100% / 12 * 4);
}

.offset-5 {
    margin-left: calc(100% / 12 * 5);
}

.offset-6 {
    margin-left: calc(100% / 12 * 6);
}

.offset-7 {
    margin-left: calc(100% / 12 * 7);
}

.offset-8 {
    margin-left: calc(100% / 12 * 8);
}

.offset-9 {
    margin-left: calc(100% / 12 * 9);
}

.offset-10 {
    margin-left: calc(100% / 12 * 10);
}

.offset-11 {
    margin-left: calc(100% / 12 * 11);
}

.g-0,
.gx-0 {
    --gap-x: 0;
}

.g-0,
.gy-0 {
    --gap-y: 0;
}

.g-1,
.gx-1 {
    --gap-x: 0.25rem;
}

.g-1,
.gy-1 {
    --gap-y: 0.25rem;
}

.g-2,
.gx-2 {
    --gap-x: 0.5rem;
}

.g-2,
.gy-2 {
    --gap-y: 0.5rem;
}

.g-3,
.gx-3 {
    --gap-x: 1rem;
}

.g-3,
.gy-3 {
    --gap-y: 1rem;
}

.g-4,
.gx-4 {
    --gap-x: 2rem;
}

.g-4,
.gy-4 {
    --gap-y: 2rem;
}

.g-5,
.gx-5 {
    --gap-x: 4rem;
}

.g-5,
.gy-5 {
    --gap-y: 4rem;
}

@media (min-width: 600px) {
    .col-sm {
        flex: 1 0 0%;
    }

    .col-sm-auto {
        width: auto;
        flex: 0 0 auto;
    }

    .col-sm-1 {
        width: calc(100% / 12);
        flex: 0 0 auto;
    }

    .col-sm-2 {
        width: calc(100% / 12 * 2);
        flex: 0 0 auto;
    }

    .col-sm-3 {
        width: calc(100% / 12 * 3);
        flex: 0 0 auto;
    }

    .col-sm-4 {
        width: calc(100% / 12 * 4);
        flex: 0 0 auto;
    }

    .col-sm-5 {
        width: calc(100% / 12 * 5);
        flex: 0 0 auto;
    }

    .col-sm-6 {
        width: calc(100% / 12 * 6);
        flex: 0 0 auto;
    }

    .col-sm-7 {
        width: calc(100% / 12 * 7);
        flex: 0 0 auto;
    }

    .col-sm-8 {
        width: calc(100% / 12 * 8);
        flex: 0 0 auto;
    }

    .col-sm-9 {
        width: calc(100% / 12 * 9);
        flex: 0 0 auto;
    }

    .col-sm-10 {
        width: calc(100% / 12 * 10);
        flex: 0 0 auto;
    }

    .col-sm-11 {
        width: calc(100% / 12 * 11);
        flex: 0 0 auto;
    }

    .col-sm-12 {
        width: 100%;
        flex: 0 0 auto;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: calc(100% / 12);
    }

    .offset-sm-2 {
        margin-left: calc(100% / 12 * 2);
    }

    .offset-sm-3 {
        margin-left: calc(100% / 12 * 3);
    }

    .offset-sm-4 {
        margin-left: calc(100% / 12 * 4);
    }

    .offset-sm-5 {
        margin-left: calc(100% / 12 * 5);
    }

    .offset-sm-6 {
        margin-left: calc(100% / 12 * 6);
    }

    .offset-sm-7 {
        margin-left: calc(100% / 12 * 7);
    }

    .offset-sm-8 {
        margin-left: calc(100% / 12 * 8);
    }

    .offset-sm-9 {
        margin-left: calc(100% / 12 * 9);
    }

    .offset-sm-10 {
        margin-left: calc(100% / 12 * 10);
    }

    .offset-sm-11 {
        margin-left: calc(100% / 12 * 11);
    }

    .g-sm-0,
    .gx-sm-0 {
        --gap-x: 0;
    }

    .g-sm-0,
    .gy-sm-0 {
        --gap-y: 0;
    }

    .g-sm-1,
    .gx-sm-1 {
        --gap-x: 0.25rem;
    }

    .g-sm-1,
    .gy-sm-1 {
        --gap-y: 0.25rem;
    }

    .g-sm-2,
    .gx-sm-2 {
        --gap-x: 0.5rem;
    }

    .g-sm-2,
    .gy-sm-2 {
        --gap-y: 0.5rem;
    }

    .g-sm-3,
    .gx-sm-3 {
        --gap-x: 1rem;
    }

    .g-sm-3,
    .gy-sm-3 {
        --gap-y: 1rem;
    }

    .g-sm-4,
    .gx-sm-4 {
        --gap-x: 2rem;
    }

    .g-sm-4,
    .gy-sm-4 {
        --gap-y: 2rem;
    }

    .g-sm-5,
    .gx-sm-5 {
        --gap-x: 4rem;
    }

    .g-sm-5,
    .gy-sm-5 {
        --gap-y: 4rem;
    }
}

@media (min-width: 900px) {
    .col-md {
        flex: 1 0 0%;
    }

    .col-md-auto {
        width: auto;
        flex: 0 0 auto;
    }

    .col-md-1 {
        width: calc(100% / 12);
        flex: 0 0 auto;
    }

    .col-md-2 {
        width: calc(100% / 12 * 2);
        flex: 0 0 auto;
    }

    .col-md-3 {
        width: calc(100% / 12 * 3);
        flex: 0 0 auto;
    }

    .col-md-4 {
        width: calc(100% / 12 * 4);
        flex: 0 0 auto;
    }

    .col-md-5 {
        width: calc(100% / 12 * 5);
        flex: 0 0 auto;
    }

    .col-md-6 {
        width: calc(100% / 12 * 6);
        flex: 0 0 auto;
    }

    .col-md-7 {
        width: calc(100% / 12 * 7);
        flex: 0 0 auto;
    }

    .col-md-8 {
        width: calc(100% / 12 * 8);
        flex: 0 0 auto;
    }

    .col-md-9 {
        width: calc(100% / 12 * 9);
        flex: 0 0 auto;
    }

    .col-md-10 {
        width: calc(100% / 12 * 10);
        flex: 0 0 auto;
    }

    .col-md-11 {
        width: calc(100% / 12 * 11);
        flex: 0 0 auto;
    }

    .col-md-12 {
        width: 100%;
        flex: 0 0 auto;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: calc(100% / 12);
    }

    .offset-md-2 {
        margin-left: calc(100% / 12 * 2);
    }

    .offset-md-3 {
        margin-left: calc(100% / 12 * 3);
    }

    .offset-md-4 {
        margin-left: calc(100% / 12 * 4);
    }

    .offset-md-5 {
        margin-left: calc(100% / 12 * 5);
    }

    .offset-md-6 {
        margin-left: calc(100% / 12 * 6);
    }

    .offset-md-7 {
        margin-left: calc(100% / 12 * 7);
    }

    .offset-md-8 {
        margin-left: calc(100% / 12 * 8);
    }

    .offset-md-9 {
        margin-left: calc(100% / 12 * 9);
    }

    .offset-md-10 {
        margin-left: calc(100% / 12 * 10);
    }

    .offset-md-11 {
        margin-left: calc(100% / 12 * 11);
    }

    .g-md-0,
    .gx-md-0 {
        --gap-x: 0;
    }

    .g-md-0,
    .gy-md-0 {
        --gap-y: 0;
    }

    .g-md-1,
    .gx-md-1 {
        --gap-x: 0.25rem;
    }

    .g-md-1,
    .gy-md-1 {
        --gap-y: 0.25rem;
    }

    .g-md-2,
    .gx-md-2 {
        --gap-x: 0.5rem;
    }

    .g-md-2,
    .gy-md-2 {
        --gap-y: 0.5rem;
    }

    .g-md-3,
    .gx-md-3 {
        --gap-x: 1rem;
    }

    .g-md-3,
    .gy-md-3 {
        --gap-y: 1rem;
    }

    .g-md-4,
    .gx-md-4 {
        --gap-x: 2rem;
    }

    .g-md-4,
    .gy-md-4 {
        --gap-y: 2rem;
    }

    .g-md-5,
    .gx-md-5 {
        --gap-x: 4rem;
    }

    .g-md-5,
    .gy-md-5 {
        --gap-y: 4rem;
    }
}

@media (min-width: 1200px) {
    .col-lg {
        flex: 1 0 0%;
    }

    .col-lg-auto {
        width: auto;
        flex: 0 0 auto;
    }

    .col-lg-1 {
        width: calc(100% / 12);
        flex: 0 0 auto;
    }

    .col-lg-2 {
        width: calc(100% / 12 * 2);
        flex: 0 0 auto;
    }

    .col-lg-3 {
        width: calc(100% / 12 * 3);
        flex: 0 0 auto;
    }

    .col-lg-4 {
        width: calc(100% / 12 * 4);
        flex: 0 0 auto;
    }

    .col-lg-5 {
        width: calc(100% / 12 * 5);
        flex: 0 0 auto;
    }

    .col-lg-6 {
        width: calc(100% / 12 * 6);
        flex: 0 0 auto;
    }

    .col-lg-7 {
        width: calc(100% / 12 * 7);
        flex: 0 0 auto;
    }

    .col-lg-8 {
        width: calc(100% / 12 * 8);
        flex: 0 0 auto;
    }

    .col-lg-9 {
        width: calc(100% / 12 * 9);
        flex: 0 0 auto;
    }

    .col-lg-10 {
        width: calc(100% / 12 * 10);
        flex: 0 0 auto;
    }

    .col-lg-11 {
        width: calc(100% / 12 * 11);
        flex: 0 0 auto;
    }

    .col-lg-12 {
        width: 100%;
        flex: 0 0 auto;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: calc(100% / 12);
    }

    .offset-lg-2 {
        margin-left: calc(100% / 12 * 2);
    }

    .offset-lg-3 {
        margin-left: calc(100% / 12 * 3);
    }

    .offset-lg-4 {
        margin-left: calc(100% / 12 * 4);
    }

    .offset-lg-5 {
        margin-left: calc(100% / 12 * 5);
    }

    .offset-lg-6 {
        margin-left: calc(100% / 12 * 6);
    }

    .offset-lg-7 {
        margin-left: calc(100% / 12 * 7);
    }

    .offset-lg-8 {
        margin-left: calc(100% / 12 * 8);
    }

    .offset-lg-9 {
        margin-left: calc(100% / 12 * 9);
    }

    .offset-lg-10 {
        margin-left: calc(100% / 12 * 10);
    }

    .offset-lg-11 {
        margin-left: calc(100% / 12 * 11);
    }

    .g-lg-0,
    .gx-lg-0 {
        --gap-x: 0;
    }

    .g-lg-0,
    .gy-lg-0 {
        --gap-y: 0;
    }

    .g-lg-1,
    .gx-lg-1 {
        --gap-x: 0.25rem;
    }

    .g-lg-1,
    .gy-lg-1 {
        --gap-y: 0.25rem;
    }

    .g-lg-2,
    .gx-lg-2 {
        --gap-x: 0.5rem;
    }

    .g-lg-2,
    .gy-lg-2 {
        --gap-y: 0.5rem;
    }

    .g-lg-3,
    .gx-lg-3 {
        --gap-x: 1rem;
    }

    .g-lg-3,
    .gy-lg-3 {
        --gap-y: 1rem;
    }

    .g-lg-4,
    .gx-lg-4 {
        --gap-x: 2rem;
    }

    .g-lg-4,
    .gy-lg-4 {
        --gap-y: 2rem;
    }

    .g-lg-5,
    .gx-lg-5 {
        --gap-x: 4rem;
    }

    .g-lg-5,
    .gy-lg-5 {
        --gap-y: 4rem;
    }
}

@media (min-width: 1500px) {
    .col-xl {
        flex: 1 0 0%;
    }

    .col-xl-auto {
        width: auto;
        flex: 0 0 auto;
    }

    .col-xl-1 {
        width: calc(100% / 12);
        flex: 0 0 auto;
    }

    .col-xl-2 {
        width: calc(100% / 12 * 2);
        flex: 0 0 auto;
    }

    .col-xl-3 {
        width: calc(100% / 12 * 3);
        flex: 0 0 auto;
    }

    .col-xl-4 {
        width: calc(100% / 12 * 4);
        flex: 0 0 auto;
    }

    .col-xl-5 {
        width: calc(100% / 12 * 5);
        flex: 0 0 auto;
    }

    .col-xl-6 {
        width: calc(100% / 12 * 6);
        flex: 0 0 auto;
    }

    .col-xl-7 {
        width: calc(100% / 12 * 7);
        flex: 0 0 auto;
    }

    .col-xl-8 {
        width: calc(100% / 12 * 8);
        flex: 0 0 auto;
    }

    .col-xl-9 {
        width: calc(100% / 12 * 9);
        flex: 0 0 auto;
    }

    .col-xl-10 {
        width: calc(100% / 12 * 10);
        flex: 0 0 auto;
    }

    .col-xl-11 {
        width: calc(100% / 12 * 11);
        flex: 0 0 auto;
    }

    .col-xl-12 {
        width: 100%;
        flex: 0 0 auto;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: calc(100% / 12);
    }

    .offset-xl-2 {
        margin-left: calc(100% / 12 * 2);
    }

    .offset-xl-3 {
        margin-left: calc(100% / 12 * 3);
    }

    .offset-xl-4 {
        margin-left: calc(100% / 12 * 4);
    }

    .offset-xl-5 {
        margin-left: calc(100% / 12 * 5);
    }

    .offset-xl-6 {
        margin-left: calc(100% / 12 * 6);
    }

    .offset-xl-7 {
        margin-left: calc(100% / 12 * 7);
    }

    .offset-xl-8 {
        margin-left: calc(100% / 12 * 8);
    }

    .offset-xl-9 {
        margin-left: calc(100% / 12 * 9);
    }

    .offset-xl-10 {
        margin-left: calc(100% / 12 * 10);
    }

    .offset-xl-11 {
        margin-left: calc(100% / 12 * 11);
    }

    .g-xl-0,
    .gx-xl-0 {
        --gap-x: 0;
    }

    .g-xl-0,
    .gy-xl-0 {
        --gap-y: 0;
    }

    .g-xl-1,
    .gx-xl-1 {
        --gap-x: 0.25rem;
    }

    .g-xl-1,
    .gy-xl-1 {
        --gap-y: 0.25rem;
    }

    .g-xl-2,
    .gx-xl-2 {
        --gap-x: 0.5rem;
    }

    .g-xl-2,
    .gy-xl-2 {
        --gap-y: 0.5rem;
    }

    .g-xl-3,
    .gx-xl-3 {
        --gap-x: 1rem;
    }

    .g-xl-3,
    .gy-xl-3 {
        --gap-y: 1rem;
    }

    .g-xl-4,
    .gx-xl-4 {
        --gap-x: 2rem;
    }

    .g-xl-4,
    .gy-xl-4 {
        --gap-y: 2rem;
    }

    .g-xl-5,
    .gx-xl-5 {
        --gap-x: 4rem;
    }

    .g-xl-5,
    .gy-xl-5 {
        --gap-y: 4rem;
    }
}

@media (min-width: 1800px) {
    .col-xxl {
        flex: 1 0 0%;
    }

    .col-xxl-auto {
        width: auto;
        flex: 0 0 auto;
    }

    .col-xxl-1 {
        width: calc(100% / 12);
        flex: 0 0 auto;
    }

    .col-xxl-2 {
        width: calc(100% / 12 * 2);
        flex: 0 0 auto;
    }

    .col-xxl-3 {
        width: calc(100% / 12 * 3);
        flex: 0 0 auto;
    }

    .col-xxl-4 {
        width: calc(100% / 12 * 4);
        flex: 0 0 auto;
    }

    .col-xxl-5 {
        width: calc(100% / 12 * 5);
        flex: 0 0 auto;
    }

    .col-xxl-6 {
        width: calc(100% / 12 * 6);
        flex: 0 0 auto;
    }

    .col-xxl-7 {
        width: calc(100% / 12 * 7);
        flex: 0 0 auto;
    }

    .col-xxl-8 {
        width: calc(100% / 12 * 8);
        flex: 0 0 auto;
    }

    .col-xxl-9 {
        width: calc(100% / 12 * 9);
        flex: 0 0 auto;
    }

    .col-xxl-10 {
        width: calc(100% / 12 * 10);
        flex: 0 0 auto;
    }

    .col-xxl-11 {
        width: calc(100% / 12 * 11);
        flex: 0 0 auto;
    }

    .col-xxl-12 {
        width: 100%;
        flex: 0 0 auto;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: calc(100% / 12);
    }

    .offset-xxl-2 {
        margin-left: calc(100% / 12 * 2);
    }

    .offset-xxl-3 {
        margin-left: calc(100% / 12 * 3);
    }

    .offset-xxl-4 {
        margin-left: calc(100% / 12 * 4);
    }

    .offset-xxl-5 {
        margin-left: calc(100% / 12 * 5);
    }

    .offset-xxl-6 {
        margin-left: calc(100% / 12 * 6);
    }

    .offset-xxl-7 {
        margin-left: calc(100% / 12 * 7);
    }

    .offset-xxl-8 {
        margin-left: calc(100% / 12 * 8);
    }

    .offset-xxl-9 {
        margin-left: calc(100% / 12 * 9);
    }

    .offset-xxl-10 {
        margin-left: calc(100% / 12 * 10);
    }

    .offset-xxl-11 {
        margin-left: calc(100% / 12 * 11);
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --gap-x: 0;
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --gap-y: 0;
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --gap-x: 0.25rem;
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --gap-y: 0.25rem;
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --gap-x: 0.5rem;
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --gap-y: 0.5rem;
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --gap-x: 1rem;
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --gap-y: 1rem;
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --gap-x: 2rem;
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --gap-y: 2rem;
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --gap-x: 4rem;
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --gap-y: 4rem;
    }
}
