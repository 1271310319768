body {
    width: 100%;
    background-color: hsl(var(--global-background));
    color: hsl(var(--global-text));
    font-family: var(--global-font);
    font-size: clamp(0.8rem, 0.76rem + 0.21vw, 1rem);
    font-weight: 400;
}

::selection {
    background: hsl(var(--global-text));
    color: hsl(var(--global-background));
}
