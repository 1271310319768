:root {
    --color-blue-lighten-5: 206, 100%, 80%;     /* #99d3ff */
    --color-blue-lighten-4: 206, 100%, 76%;     /* #85caff */
    --color-blue-lighten-3: 206, 100%, 72%;     /* #70c1ff */
    --color-blue-lighten-2: 206, 100%, 68%;     /* #5cb8ff */
    --color-blue-lighten-1: 206, 100%, 64%;     /* #47afff */
    --color-blue: 206, 100%, 60%;               /* #35a7ff */
    --color-blue-darken-1: 206, 100%, 56%;      /* #1f9eff */
    --color-blue-darken-2: 206, 100%, 52%;      /* #0a95ff */
    --color-blue-darken-3: 206, 100%, 48%;      /* #008bf5 */
    --color-blue-darken-4: 206, 100%, 44%;      /* #007fe0 */
    --color-blue-darken-5: 206, 100%, 40%;      /* #0074cc */
    --color-green-lighten-5: 147, 93%, 48%;     /* #09ec6f */
    --color-green-lighten-4: 147, 93%, 44%;     /* #08d966 */
    --color-green-lighten-3: 147, 93%, 40%;     /* #07c55d */
    --color-green-lighten-2: 147, 93%, 36%;     /* #06b153 */
    --color-green-lighten-1: 147, 93%, 32%;     /* #069d4a */
    --color-green: 147, 93%, 28%;               /* #058a41 */
    --color-green-darken-1: 147, 93%, 24%;      /* #047638 */
    --color-green-darken-2: 147, 93%, 20%;      /* #04622e */
    --color-green-darken-3: 147, 93%, 16%;      /* #034f25 */
    --color-green-darken-4: 147, 93%, 12%;      /* #023b1c */
    --color-green-darken-5: 147, 93%, 8%;       /* #012713 */
    --color-yellow-lighten-5: 61, 100%, 88%;    /* #feffc2 */
    --color-yellow-lighten-4: 61, 100%, 84%;    /* #feffad */
    --color-yellow-lighten-3: 61, 100%, 80%;    /* #fdff99 */
    --color-yellow-lighten-2: 61, 100%, 76%;    /* #fdff85 */
    --color-yellow-lighten-1: 61, 100%, 72%;    /* #fdff70 */
    --color-yellow: 61, 100%, 68%;              /* #fcff5c */
    --color-yellow-darken-1: 61, 100%, 64%;     /* #fcff47 */
    --color-yellow-darken-2: 61, 100%, 60%;     /* #fcff33 */
    --color-yellow-darken-3: 61, 100%, 56%;     /* #fbff1f */
    --color-yellow-darken-4: 61, 100%, 52%;     /* #fbff0a */
    --color-yellow-darken-5: 61, 100%, 48%;     /* #f1f500 */
    --color-orange-lighten-5: 36, 100%, 72%;    /* #ffc670 */
    --color-orange-lighten-4: 36, 100%, 68%;    /* #ffbe5c */
    --color-orange-lighten-3: 36, 100%, 64%;    /* #ffb647 */
    --color-orange-lighten-2: 36, 100%, 60%;    /* #ffad33 */
    --color-orange-lighten-1: 36, 100%, 56%;    /* #ffa51f */
    --color-orange: 36, 100%, 52%;              /* #ff9d0a */
    --color-orange-darken-1: 36, 100%, 48%;     /* #f59300 */
    --color-orange-darken-2: 36, 100%, 44%;     /* #e08700 */
    --color-orange-darken-3: 36, 100%, 40%;     /* #cc7a00 */
    --color-orange-darken-4: 36, 100%, 36%;     /* #b86e00 */
    --color-orange-darken-5: 36, 100%, 32%;     /* #a36200 */
    --color-red-lighten-5: 355, 78%, 76%;       /* #f2919a */
    --color-red-lighten-4: 355, 78%, 72%;       /* #ef8089 */
    --color-red-lighten-3: 355, 78%, 68%;       /* #ed6e78 */
    --color-red-lighten-2: 355, 78%, 64%;       /* #eb5c68 */
    --color-red-lighten-1: 355, 78%, 60%;       /* #e94957 */
    --color-red: 355, 78%, 56%;                 /* #e63946 */
    --color-red-darken-1: 355, 78%, 52%;        /* #e42535 */
    --color-red-darken-2: 355, 78%, 48%;        /* #da1b2b */
    --color-red-darken-3: 355, 78%, 44%;        /* #c81927 */
    --color-red-darken-4: 355, 78%, 40%;        /* #b61624 */
    --color-red-darken-5: 355, 78%, 36%;        /* #a31420 */
    --color-white: 0, 0%, 100%;                 /* #fff */
    --color-white-darken-1: 0, 0%, 96%;         /* #f5f5f5 */
    --color-white-darken-2: 0, 0%, 92%;         /* #ebebeb */
    --color-white-darken-3: 0, 0%, 88%;         /* #e0e0e0 */
    --color-white-darken-4: 0, 0%, 84%;         /* #d6d6d6 */
    --color-white-darken-5: 0, 0%, 80%;         /* #ccc */
    --color-black-lighten-5: 0, 0%, 20%;
    --color-black-lighten-4: 0, 0%, 16%;
    --color-black-lighten-3: 0, 0%, 12%;
    --color-black-lighten-2: 0, 0%, 8%;
    --color-black-lighten-1: 0, 0%, 4%;
    --color-black: 0, 0%, 0%;
    --color-gray-lighten-5: 0, 0%, 56%;         /* #8f8f8f */
    --color-gray-lighten-4: 0, 0%, 52%;         /* #858585 */
    --color-gray-lighten-3: 0, 0%, 48%;         /* #7a7a7a */
    --color-gray-lighten-2: 0, 0%, 44%;         /* #707070 */
    --color-gray-lighten-1: 0, 0%, 40%;         /* #666 */
    --color-gray: 0, 0%, 36%;                   /* #5c5c5c */
    --color-gold: 37, 72%, 55%;                 /* #dfa13a */
}
